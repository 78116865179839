import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["container"]

  connect() {
    this.fieldIndex = this.element.querySelectorAll(".nested-fields").length;
  }

  add(event) {
    event.preventDefault();
    const template = event.target.dataset.fields
    const targetClass = event.target.dataset.targetClass
    const newElement = document.createElement("div")
    newElement.classList.add(targetClass)
    newElement.innerHTML = template

    // Append the new element to the container
    this.containerTarget.appendChild(newElement.firstElementChild)
    this.fieldIndex++
  }

  remove(event) {
    event.preventDefault();
    const field = event.target.closest(".nested-fields");
    field.querySelector("input[name*='_destroy']").value = 1;
    field.style.display = "none";
  }
}
